import * as Sentry from "@sentry/astro";
import { version } from "package.json";
const sampleRate = import.meta.env.PUBLIC_SENTRY_SESSION_SAMPLE_RATE || '0';
const errorSampleRate = import.meta.env.PUBLIC_SENTRY_ON_ERROR_SAMPLE_RATE || '0';
const sentryEnv = import.meta.env.MODE; // returns development or production

console.log('sentry.client.config.js loaded');
//console.log('version', version);

Sentry.init({
  	dsn: "https://43ba6cd26107f257601ba7270b60d71a@o4507979212128256.ingest.de.sentry.io/4508002408005712",
	debug: sentryEnv === 'development' ? true : false,
	version: version,
	replaysSessionSampleRate: parseFloat(sampleRate),
	replaysOnErrorSampleRate: parseFloat(errorSampleRate),
  	integrations: [
    	new Sentry.Replay({
      		maskAllText: true
    	}),
  	],
  	allowUrls: [
		/rotacloud\.com/,
		/rotacloud\.netlify\.app/, 				// deploy previews
		// /^https:\/\/polyfill\.io/, 				// polyfills
		/^https:\/\/widget\.intercom\.io/, 		// intercom
		/^https:\/\/fast\.wistia\.com/, 		// video embeds
	],
	ignoreErrors: [
		"https://pagead2.googlesyndication.com/pagead/buyside_topics/set/",
	]
});